<template>
    <el-dialog

            :visible.sync="dialogVisible"
            width="800px"
            :close-on-click-modal="false"
            custom-class="my-box-34dj"
    >
        <template #title>
            <div style="width: 100%;display: flex;align-items: center;justify-content: center">
                全球开店
            </div>
        </template>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="公司名"><el-input v-model="form.name" placeholder="公司名"></el-input></el-form-item>
            <el-form-item label="公司地址"><el-input v-model="form.name" placeholder="公司地址"></el-input></el-form-item>
            <el-form-item label="公司规模"><el-input v-model="form.name" placeholder="公司规模"></el-input></el-form-item>
            <el-form-item label="行业"><el-input v-model="form.name" placeholder="行业"></el-input></el-form-item>
            <el-form-item label="联系人"><el-input v-model="form.name" placeholder="联系人"></el-input></el-form-item>
            <el-form-item label="联系电话"><el-input v-model="form.name" placeholder="联系电话"></el-input></el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
	name: "",
	data() {
		return {
			dialogVisible: false,
			form: {}
		}
	},
	methods: {
		save() {
			this.$confirm('申请成功!')
			this.confirm()
		},
		close() {
			this.confirm()
		}
	}
}
</script>
<style lang="less">
.my-box-34dj{
  background: rgb(221, 240, 250);
}
</style>
