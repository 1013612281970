<template>
    <div class="box">
        <div>
            <div style="flex: 1">
                <dv-border-box-11 title="北美">
                    <div class="shop_box">
                        <RightChart1 :config="AmericaConfig" total="2000"></RightChart1>
                    </div>
                </dv-border-box-11>
            </div>
            <div style="flex: 1">
                <dv-border-box-11 title="欧洲">
                    <div class="shop_box">
                        <RightChart1 :config="EUConfig" total="2000"></RightChart1>
                    </div>
                </dv-border-box-11>
            </div>
        </div>
        <div>
            <div style="flex: 1">
                <dv-border-box-11 title="日韩">
                    <div class="shop_box">
                        <RightChart1 :config="JPKConfig" total="2000"></RightChart1>
                    </div>
                </dv-border-box-11>
            </div>
            <div style="flex: 1">
                <dv-border-box-11 title="东南亚">
                    <div class="shop_box">
                        <RightChart1 :config="SEAConfig" total="2000"></RightChart1>
                    </div>
                </dv-border-box-11>
            </div>
        </div>
        <div>
            <div style="flex: 1">
                <dv-border-box-11 title="拉美">
                    <div class="shop_box">
                        <RightChart1 :config="SAConfig" total="2000"></RightChart1>
                    </div>
                </dv-border-box-11>
            </div>
            <div style="flex: 1">
                <dv-border-box-11 title="非洲">
                    <div class="shop_box">
                        <RightChart1 :config="AFConfig" total="2000"></RightChart1>
                    </div>
                </dv-border-box-11>
            </div>
        </div>
    </div>
</template>

<script>
import {createModel} from "@/views/data-report/create-model";
import shopBox from "@/views/data-report/shopBox";
import lazada from '@/assets/imgs/lazada.png'
import shopify from '@/assets/imgs/shopify.svg'
import shopee from '@/assets/imgs/shopee.png'
import amazon from '@/assets/imgs/amazon.svg'
import RightChart1 from "@/views/data-report/chart/RightChart1";

export default {
	name: "shop",
	components: {
		RightChart1
	},
	data() {
		return {
			lazada, shopify, shopee, amazon,
			AmericaConfig: {
				data: [
					{
						name: 'Amazon',
						value: 13659
					},
					{
						name: 'shopify',
						value: 9151
					},
					{
						name: 'eBay',
						value: 3254
					},
					{
						name: '沃尔玛',
						value: 2123
					},
					{
						name: '新蛋',
						value: 1867
					},
					{
						name: 'wish',
						value: 1298
					},
					{
						name: 'Temu',
						value: 1118
					},
					{
						name: 'wayfair',
						value: 923
					},
					{
						name: 'etsy',
						value: 672
					},

				],
				unit: '店铺'
			},
			JPKConfig: {
				data: [
					{
						name: 'Amazon',
						value: 3857
					},
					{
						name: '乐天',
						value: 2151
					},
					{
						name: 'Coupang',
						value: 1856
					},
					{
						name: 'Yahoo',
						value: 1123
					},

				],
				unit: '店铺'
			}, EUConfig: {
				data: [
					{
						name: 'Amazon',
						value: 8857
					},
					{
						name: 'shopify',
						value: 5151
					},
					{
						name: 'eBay',
						value: 3856
					},
					{
						name: '速卖通',
						value: 3123
					},
					{
						name: 'OZON',
						value: 523
					},
					{
						name: 'ALLegro',
						value: 311
					},

				],
				unit: '店铺'
			},
			SEAConfig: {
				data: [
					{
						name: 'Lazada',
						value: 5271
					},
					{
						name: 'Tik Tok',
						value: 1426
					},
					{
						name: 'shopee',
						value: 1256
					},
					{
						name: 'Thisshop',
						value: 999
					},
					{
						name: 'shopify',
						value: 523
					},

				],
				unit: '店铺'
			},
			SAConfig: {
				data: [
					{
						name: '亚马逊',
						value: 5271
					},
					{
						name: '美客多',
						value: 3426
					},


				],
				unit: '店铺'
			}, AFConfig: {
				data: [
					{
						name: 'Jumia',
						value: 1271
					},

				],
				unit: '店铺'
			},
		}
	},
	methods: {
		open() {
			createModel(shopBox)
		}
	}
}
</script>

<style scoped lang="less">
.box {
  padding: 8px 0;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;

    &.center {
      flex: 1;

    }

    &.right {
      flex: 1;
    }
  }

  .shop_box {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 50px 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
}
</style>
